<template>
	<div class="layout">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {};
</script>

<style lang="scss" scoped>
	.layout {
		height: 100%;

		.member {
			height: 100%;
		}
		.manage {
			height: 100%;
		}
	}
</style>